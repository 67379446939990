<template>
  <div>
    <div class="row justify-content-center">
      <div class="card col-sm-12 col-md-12 p-1">
        <div>
          <div>
            <b-form>
              <div class="h2" style="color: #558cef">ยืมทรัพย์สิน</div>
              <hr />
              <div>
                <validation-observer ref="observer" v-slot="{ passes }">
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ทะเบียนยืม:">
                      <template v-slot:label> ทะเบียนยืม <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          class="col-12"
                          id="basicInput"
                          v-model="equipment_code"
                          placeholder="AUTO"
                          disabled
                        />
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ลงวันที่:">
                      <template v-slot:label> ลงวันที่ <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-date-picker
                          id="example-datepicker3"
                          label="ลงวันที่:"
                          locale="th-TH"
                          v-model="date1"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="formatDate(inputValue, 'date1')"
                              v-on="inputEvents"
                              :state="errors.length > 0 ? false : null"
                            />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </validation-provider>
                    </b-form-group>
                    <!-- <b-form-group
                    class="col-md-4"
                    label-cols="3"
                    label-cols-lg="3"
                    label="วันที่รับ:"
                  >
                    <template v-slot:label>
                      วันที่รับ <span class="text-danger"> *</span>
                    </template>
                    <b-form-datepicker
                      placeholder="วันที่"
                      id="example-datepicker"
                      v-model="from.datein"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      }"
                      locale="th"
                    />
                  </b-form-group> -->
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ปีงบประมาณ:">
                      <template v-slot:label> ปีงบประมาณ <span class="text-danger"> </span> </template>

                      <b-form-input
                        maxlength="4"
                        id="basicInput"
                        v-model="budget_year"
                        placeholder="ปีงบประมาณ"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      />
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="เลขที่เอกสาร:">
                      <template v-slot:label> เลขที่เอกสาร <span class="text-danger"> </span> </template>

                      <b-form-input id="basicInput" v-model="important_number" placeholder="เลขที่เอกสาร" />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ประเภทหลักฐาน:">
                      <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> </span> </template>

                      <v-select placeholder="ประเภทหลักฐาน" label="evidence" v-model="proof" :options="proofs" />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="วันที่ยืม:">
                      <template v-slot:label> วันที่ยืม <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่ยืม:"
                          locale="th-TH"
                          v-model="date2"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="formatDate(inputValue, 'date2')"
                              v-on="inputEvents"
                              :state="errors.length > 0 ? false : null"
                            />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="กลุ่มงาน:">
                      <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                      <v-select
                        placeholder="เลือกกลุ่มงาน"
                        label="work_gid_work_gname"
                        v-model="workG"
                        :options="workGs"
                        @input="work_select($event)"
                      />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="กลุ่มงาน:">
                      <template v-slot:label> ฝ่ายงาน/ตำเเหน่ง <span class="text-danger"> *</span> </template>
                      <v-select
                        placeholder="เลือกฝ่ายงาน"
                        label="dep_id_dep_name"
                        v-model="department"
                        :options="departments"
                        @input="fullnameFromWorkgroupAndDepartment"
                      />
                    </b-form-group>
                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="ผู้ยืม:">
                      <template v-slot:label> ผู้ยืม <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-select
                          :options="alluser_user"
                          label="fullname"
                          :state="errors.length > 0 ? false : null"
                          id="basicInput"
                          v-model="req_name"
                          placeholder="ผู้ยืม:"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-md-4" label-cols="3" label-cols-lg="3" label="วันที่คืน:">
                      <template v-slot:label> วันที่คืน <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <v-date-picker
                          id="example-datepicker3"
                          label="วันที่คืน:"
                          locale="th-TH"
                          v-model="date3"
                          @update:from-page="getHeaderInputDate"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <b-form-input
                              :value="formatDate(inputValue, 'date3')"
                              v-on="inputEvents"
                              :state="errors.length > 0 ? false : null"
                            />
                          </template>
                          <template #header-title>
                            {{ headerInputDate }}
                          </template>
                        </v-date-picker>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-md-12" label-cols="3" label-cols-lg="1" label="เหตุผล/ความจำเป็น:">
                      <template v-slot:label> เหตุผล/ความจำเป็น <span class="text-danger"> *</span> </template>
                      <validation-provider #default="{ errors }" rules="required">
                        <b-form-textarea
                          maxlength="250"
                          :state="errors.length > 0 ? false : null"
                          v-model="reason"
                          id="basicInput"
                          placeholder="เหตุผล/ความจำเป็น"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-row>

                  <div class="row">
                    <b-button class="ml-2" variant="gradient-primary" v-b-modal.modal-1 @click="c_select">
                      เลือกรายการทรัพย์สิน
                    </b-button>
                    <b-button
                      class="ml-1"
                      variant="gradient-success"
                      align-self="end"
                      @click.prevent="passes(postdata)"
                    >
                      ยืมทรัพย์สิน
                    </b-button>
                  </div>
                </validation-observer>
                <b-modal
                  id="modal-1"
                  title="เลือกรายการทรัพย์สิน"
                  ok-only
                  ok-title="ตกลง"
                  size="lg"
                  @ok="addItem"
                  no-close-on-backdrop
                >
                  <div>
                    <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                      <v-select
                        placeholder="เลือกกลุ่มงาน"
                        label="work_gid_work_gname"
                        v-model="workG"
                        :options="workGs"
                        @input="work_select($event)"
                      />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มทรัพย์สิน:">
                      <v-select
                        placeholder="เลือกกลุ่มทรัพย์สิน"
                        v-model="selectedG"
                        label="e_gname"
                        :options="selectedGs"
                        @input="item_select($event)"
                      />
                    </b-form-group>
                    <b-form-group label-cols="3" label-cols-lg="2" label="ค้นหา:">
                      <b-form-input placeholder="ค้นหา" v-model="searchTermitem" />
                    </b-form-group>
                    <vue-good-table
                      :columns="materialGroup"
                      :rows="material"
                      :search-options="{
                        enabled: true,
                        externalQuery: searchTermitem,
                      }"
                      :select-options="{
                        enabled: true,
                      }"
                      @on-selected-rows-change="selectionChanged"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'image'">
                          <span>
                            <b-button
                              variant="outline-warning"
                              v-b-modal="`modal-image${props.index}`"
                              @click="get_image(props.row)"
                            >
                              <feather-icon icon="ImageIcon" class="mr-50" />
                            </b-button>
                            <!-- basic modal -->
                            <b-modal
                              :id="`modal-image${props.index}`"
                              title="ดูรูปภาพ"
                              ok-only
                              ok-title="ปิด"
                              no-close-on-backdrop
                            >
                              <b-card-text class="text-center">
                                <div v-if="image_p != null">
                                  <swiper
                                    ref="mySwiper"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="swiperOptions"
                                  >
                                    <swiper-slide v-for="image_p in image_p" :key="image_p">
                                      <b-img
                                        width="250%"
                                        height="250%"
                                        src
                                        :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                        class="mx-1"
                                      />
                                    </swiper-slide>
                                    <div slot="button-next" class="swiper-button-next" />
                                    <div slot="button-prev" class="swiper-button-prev" />
                                    <div slot="pagination" class="swiper-pagination" />
                                  </swiper>
                                  <!-- <div class="text-center" v-for="image_p in image_p" :key="image_p">
                                    <img
                                      width="50%"
                                      height="50%"
                                      src
                                      :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                      class="mx-1"
                                    />
                                  </div> -->
                                </div>
                                <span v-else>
                                  <div class="text-center">ไม่มีรูปภาพ</div>
                                </span>
                              </b-card-text>
                            </b-modal>
                          </span>
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </b-modal>
              </div>
            </b-form>
          </div>
        </div>
        <hr />
        <div class="h2" style="color: #558cef">รายการยืม</div>
        <hr />
        <!-- table -->
        <vue-good-table :columns="picking" :rows="pickings" :line-numbers="true">
          <template slot="table-row" slot-scope="props">
            <!-- {{ props.row }} -->
            <span v-if="props.column.field === 'remark'" class="text-nowrap">
              <textarea
                v-model="props.row.remark"
                id=""
                cols="50"
                rows="3"
                @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
              >
              </textarea>
            </span>
            <span v-else-if="props.column.field === 'delete_quantity'">
              <span>
                <b-button @click="delete_data_value(props.index)" variant="danger">ลบ</b-button>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <div class="row justify-content-around">
      <div class="col-sm-12 col-md-12 card p-1">
        <div class="title head">
          <p class="h2" style="color: #558cef">รายการยืมทรัพย์สิน</p>
        </div>
        <hr />
        <!-- search input -->
        <div class="custom-search row justify-content-end">
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">ค้นหา</label>
              <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
              <div>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <b-button variant="outline-primary">
                      <feather-icon icon="ShareIcon" class="mr-50" />Export
                      <feather-icon icon="ChevronDownIcon" class="mr-50" />
                    </b-button>
                  </template>

                  <b-dropdown-item @click="exportExcel">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    Excel
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'status_type'" class="text-nowrap">
                <div v-if="props.row.status_type === 'ยืม'">
                  <b-button variant="warning"> รออนุมัติ{{ props.row.status_type }} </b-button>
                </div>
                <div v-else-if="props.row.status_type === 'ไม่อนุมัติยืม'">
                  <b-button variant="danger"> {{ props.row.status_type }} </b-button>
                </div>
                <div v-else-if="props.row.status_type === 'อนุมัติยืม'">
                  <b-button variant="success">
                    {{ props.row.status_type }}
                  </b-button>
                </div>
                <div v-else-if="props.row.status_type === 'ส่งคืน'">
                  <b-button variant="info">
                    {{ props.row.status_type }}
                  </b-button>
                </div>
                <div v-else-if="props.row.status_type === 'คืน'">
                  <b-button variant="info"> ส่ง{{ props.row.status_type }} </b-button>
                </div>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button variant="outline-warning" @click="dateField(props)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                  </b-button>
                </span>
              </span>
              <span v-else-if="props.column.field === 'edit'">
                <span v-if="status_user == 'ผู้ใช้ทั่วไป'">
                  <div v-if="props.row.status_type == 'ไม่อนุมัติยืม' || props.row.status_type == 'ยืม'">
                    <b-button variant="outline-warning" @click="edit_data_value(props.row)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                    </b-button>
                  </div>
                </span>
                <span v-else>
                  <b-button variant="outline-warning" @click="edit_data_value(props.row)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                  </b-button>
                </span>
              </span>
              <span v-else-if="props.column.field === 'delete'">
                <span>
                  <span v-if="status_user == 'ผู้ใช้ทั่วไป'">
                    <div v-if="props.row.status_type == 'ไม่อนุมัติยืม' || props.row.status_type == 'ยืม'">
                      <b-button variant="outline-warning" @click="del_data(props.row.equipment_code)">
                        <feather-icon icon="Trash2Icon" class="mr-50" />
                      </b-button>
                    </div>
                    <div v-else></div>
                  </span>
                  <span v-else>
                    <b-button variant="outline-warning" @click="del_data(props.row.equipment_code)">
                      <feather-icon icon="Trash2Icon" class="mr-50" />
                    </b-button>
                  </span>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                  />
                  <span class="text-nowrap">of {{ total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="handlePagechange($event)"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { DatePicker } from "v-calendar";
const dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
var isToday = require("dayjs/plugin/isToday");
dayjs.extend(isToday);
dayjs.extend(isBetween);
import { BImg } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,

    BImg,
    Swiper,
    SwiperSlide,
    DatePicker,
  },

  data() {
    return {
      pickings_temp: [],
      equipment_code_gen: "",
      registration_number: "",
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),
      date3: new Date(),
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      searchTermitem: "",
      show: false,
      pageLength: 25,
      currentPage: 1,
      total: "",
      equipment_code: "",
      from: [
        {
          datein: "",
          datenow: "",
          dateneed: "",
          takeback_date: "",
          takeback_date_happen: "",
          datareturn: "",
          borrow_date: "",
        },
      ],
      budget_year: "",
      important_number: "",
      req_name: "",
      reason: "",
      workG: null,
      workGs: [],
      proof: null,
      proofs: [],
      materialGroup: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "รายละเอียด",
          field: "information",
        },
        {
          label: "รูป",
          field: "image",
        },
      ],
      material: [],
      department: null,
      departments: [],
      selectedG: null,
      selectedGs: [],
      picking: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "หมายเลขทรัพย์สิน",
          field: "serial_number",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
        },
        {
          label: "รายละเอียด",
          field: "information",
        },
        {
          label: "หมายเหตุ",
          field: "remark",
        },
        {
          label: "ลบ",
          field: "delete_quantity",
        },
      ],
      pickings: [],
      columns: [
        {
          label: "วัน-เดือน-ปี (วันที่ยืม)",
          field: "takeback_date_th",
        },
        {
          label: "วัน-เดือน-ปี (วันที่คืน)",
          field: "takeback_date_happen_th",
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
        },
        {
          label: "กลุ่มงาน",
          field: "work_gname",
        },
        {
          label: "ผู้ยืม",
          field: "req_name",
        },

        {
          label: "สถานะ",
          field: "status_type",
        },
        {
          label: "ดูรายการ",
          field: "action",
        },
        {
          label: "แก้ไข",
          field: "edit",
        },
        {
          label: "ลบ",
          field: "delete",
        },
      ],
      post_data: [],
      rows: [],
      searchTerm: "",
      selectMode: "multi",
      selected: [],
      listget: [],
      addItemList: [],
      user: "",
      API: `${API}`,
      image_p: null,
      alluser: [],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      status_user: "",
      username_user: "",
      alluser_user: [],
      getlog: "",
    };
  },

  async created() {
   
    const { usertype, username } = await JSON.parse(localStorage.getItem("username_type"));
    this.status_user = await usertype;
    this.username_user = await username;
    this.user = await username;
    this.material_code();
    this.work();
    this.item();
    this.gettable();
    this.get_data_table();
    this.s_evidenceTypes();
    this.auto_();
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
      return;
    },
    async auto_() {
      const { work_gid, dep_id, fullname } = await JSON.parse(localStorage.getItem("username_type"));

      const { access_token } = await this.access_token();
      const url_herd = `${API}headWorkgroup?work_gid=${work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header);

      // const dep_id_dep_name = `${API}departments?dep_name=${dep_id}`;
      // const res_dep_id_dep_name = await axios.get(dep_id_dep_name, header);
      console.log(res_heard.data.message.data);
      // console.log(res_dep_id_dep_name.data.message.data);

      const aaa = [...res_heard.data.message.data];
      console.log(aaa);
      const [user] = res_heard.data.message.data.map((el) => {
        return {
          ...el,

          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      console.log(user);
      this.workG = user;
      this.department = user;
      this.req_name = user;
      this.req_name_ = fullname;
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      //  console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      // console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.from.borrow_date = dete_val;
            break;
          case "date2":
            this.from.takeback_date = dete_val;
            break;
          case "date3":
            this.from.takeback_date_happen = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    async edit_data_value(value) {
      this.registration_number = value.equipment_code;
      this.equipment_code_gen = "false";
      console.log(value);
      this.date1 = new Date(value.borrow_date).toString();
      this.date2 = new Date(value.takeback_date).toString();
      this.date3 = new Date(value.takeback_date_happen).toString();
      const { access_token } = await this.access_token();
      const url = `${API}borrowHistoryList?equipment_code=${value.equipment_code}`;
      const url_work_gid = `${API}departments?work_gid=${value.work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      const res_work_gid = await axios.get(url_work_gid, header);
      console.log(res);
      console.log(res_work_gid);
      console.log(value.dep_id);
      const { dep_name } = await res_work_gid.data.message.data.find((el) => el.dep_id === value.dep_id);

      console.log(dep_name);

      console.log(value);
      console.log(dep_name);
      this.post_data = value;
      this.equipment_code = value.equipment_code;
      this.req_name = value.req_name;
      this.budget_year = value.budget_year;
      this.important_number = value.important_number;
      this.proof = value.evidence_type_1;
      this.workG = `${value.work_gid} - ${value.work_gname}`;
      this.department = `${value.dep_id} - ${dep_name}`;
      this.reason = value.reason;
      this.from.borrow_date = this.date1;
      this.from.takeback_date = this.date2;
      this.from.takeback_date_happen = this.date3;
      this.pickings = res.data.message.data;
      console.log(this.department);
      // console.log(this.equipment_code);
    },
    delete_data_value(value) {
      this.material.push(this.pickings[value]);
      this.pickings = this.pickings.filter((el, index) => index != value);
      this.pickings_temp = this.pickings_temp.filter((el, index) => index != value);
      console.log(this.pickings);
      console.log(this.pickings_temp);
    },
    c_select() {
      if (this.equipment_code_gen === "" || this.equipment_code_gen === "true") {
        this.equipment_code_gen = "true";
        this.registration_number = "";
      } else {
        this.equipment_code_gen = "false";
        // this.registration_number = '';
      }
      const pickings = this.pickings.length;
      if (pickings === 0) {
      } else {
        let pickings = this.pickings;
        let material = this.material;
        console.log(pickings);
        let res = material.filter((el) => {
          return !pickings.find((element) => {
            return element.e_number == el.e_number;
          });
        });

        this.material = res;
      }
    },
    changeCell(vaule, column, row) {
      // console.log(vaule, column, row);
      this.pickings[row][column] = vaule;
    },
    async fullnameFromWorkgroupAndDepartment() {
      const { access_token } = await this.access_token();
      // console.log(this.workG);
      // console.log(this.department);
      const url = `${API}fullnameFromWorkgroupAndDepartment?work_gid=${this.workG.work_gname}&dep_id=${this.department.dep_name}`;
      // console.log(url);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      res.data.message.data.forEach(async (element) => {
        const url_b = `${API}registerWithdraw?fullname=${element.fullname}`;
        const res_b = await axios.get(url_b, head);
        console.log(res_b.data.message.data);
      });

      // console.log(res.data.message.data);
      this.alluser_user = res.data.message.data;
    },
    async s_evidenceTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.proofs = res.data.message.data;
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
      // console.log(res.data.message);
    },
    async get_image(value) {
      this.image_p = null;
      // console.log(value.e_number);
      const { access_token } = await this.access_token();
      this.token = access_token;
      const api = `${API}equipmentDetail?e_number=${value.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(api, head);
      // console.log(res.data.message.data[0].e_image);
      if (res.data.message.data[0].e_image) {
        this.image_p = res.data.message.data[0].e_image;
        // console.log(JSON.parse(this.image_p));
        this.image_p = JSON.parse(this.image_p);
        this.image_p = this.image_p;
        // console.log(this.image_p);
      } else {
        this.image_p = null;
        // console.log('ไม่มีรูป');
      }
    },
    async del_data(del_data) {
      // console.log(del_data);
      console.log(this.user);
      console.log(del_data);
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `เลขทะเบียน ${del_data}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          // console.log(del_data);
          const { access_token } = await this.access_token();
          const url = `${API}borrow/${del_data}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          // console.log(url);
          console.log(del_data);
          const url_a = `${API}delHistoryLog/${del_data}`;
          const res_a = await axios.delete(url_a, head);
          console.log(res_a);

          const res = await axios.delete(url, head);
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ!",
            text: `รายการนี้ ถูกลบเเล้ว ${del_data}`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.logUser("ลบรายการยืมทรัพย์สิน");
          this.gettable();
        }
      });
    },
    async postdata() {
      this.checkLogout();
      if (this.pickings.length === 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: ``,
          title: "กรุณาเลือกรายการทรัพย์สิน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const { access_token } = await this.access_token();
      const head_checkTaken = {
        headers: {
          Authorization: await access_token,
        },
      };

      // console.log(this.pickings);
      // console.log(JSON.stringify(this.pickings_temp));

      const checkTaken_url = `${API}checkTakenEquipment`;
      const checkTaken_data = {
        data: JSON.stringify(this.pickings_temp),
        task: "ยืม",
      };
      // console.log(checkTaken_data)
      //  const res_checkTaken = await axios.post(checkTaken_url, checkTaken_data, head_checkTaken);

      // console.log(res_checkTaken);

      // if (res_checkTaken.data.status === 400) {

      //   Swal.fire({
      //     position: "center",
      //     icon: "error",
      //     text: `${res_checkTaken.data.message}`,
      //     title: "ทรัพย์สินมีการยืมเเล้ว",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }

      console.log(this.rows);
      console.log(this.from.takeback_date);
      console.log(this.from.takeback_date_happen);

      var text_a = "ยื่มได้";
      var xx;
      var _arr = [];
      xx = await this.rows.forEach(async (el) => {
        //   console.log(dayjs(this.from.takeback_date_happen).isBetween(el.takeback_date, el.takeback_date_happen, null, "[)"));

        if (
          dayjs(this.from.takeback_date).isBetween(el.takeback_date, el.takeback_date_happen, null, "[)") &&
          el.status_type.toString() === "อนุมัติยืม"
        ) {
          //  console.log("วันที่ยืม ตรงกับรายการ");
          //  console.log(el.equipment_code);
          // text_a = "ยื่มไม่ได้";

          const url_i = `${API}borrowHistoryList?equipment_code=${el.equipment_code}&_page=1&_limit=100&_sort=-1`;
          const res_i = await axios.get(url_i, head_checkTaken);
          //  console.log(res_i.data.message.data);
          // console.log(el)
          // console.log(this.pickings);
          this.pickings.map(async (el1) => {
            console.log(el1.e_number);
            xx = res_i.data.message.data.find((element) => element.e_number.toString() === el1.e_number.toString());
            return xx;
          });
          console.log(xx);
          if (xx == undefined) {
            console.log("ยื่มได้");
            ///////////////////////////////////////////////////////
            return;
          } else if (xx) {
            console.log("ยื่มไม่ได้");
            _arr.push("ยื่มไม่ได้");
            Swal.fire({
              position: "center",
              icon: "error",
              title: "รายการนี้มีการทำรายการเเล้ว",
              showConfirmButton: false,
              timer: 1500,
            });
            return;
          }
          // if (dayjs(el.takeback_date_happen).isBefore(dayjs(this.from.takeback_date))) {
          // if (dayjs(el.takeback_date).isBetween(this.from.takeback_date, dayjs(this.from.takeback_date_happen), "days")) {
          //   console.log("วันที่คืน ตรงกับรายการ");
          // }

          //  console.log(this.rows);
        } else if (
          dayjs(this.from.takeback_date_happen).isBetween(el.takeback_date, el.takeback_date_happen, null, "[)") &&
          el.status_type.toString() === "อนุมัติยืม"
        ) {
          console.log("ยื่มไม่ได้");
          _arr.push("ยื่มไม่ได้");
          return;
        } else {
          console.log(dayjs(this.from.takeback_date_happen).isBefore(el.takeback_date));
          const ssss = dayjs(this.from.takeback_date_happen).isBefore(el.takeback_date);
          if (ssss == false) {
            const rrr = dayjs(this.from.takeback_date_happen).isAfter(el.takeback_date_happen);
            console.log(rrr);
            if (rrr == true) {
              const aaa = dayjs(this.from.takeback_date).isBefore(el.takeback_date);
              if (aaa == true) {
                _arr.push("ยื่มไม่ได้");
                console.log("ยื่มไม่ได้");
                return;
              } else {
                console.log("ยืมได้");
                ///////////////////////////////////////////////////////////////
              }
            } else {
              console.log(dayjs(this.from.takeback_date).isToday(el.takeback_date));
              const _today_takeback_date = dayjs(this.from.takeback_date).isToday(el.takeback_date);
              const _today_takeback_date_happen = dayjs(this.from.takeback_date_happen).isToday(
                el.takeback_date_happen
              );
              if (_today_takeback_date == true && _today_takeback_date_happen == true) {
                console.log("ยืมได้");
              } else {
                _arr.push("ยื่มไม่ได้");
                console.log("ยื่มไม่ได้");
                return;
              }
            }
          }
        }
        //  else {

        //   if (dayjs(this.from.takeback_date_happen).isBetween(el.takeback_date, el.takeback_date_happen, null, "[)")) {
        //       console.log("ยื่มไม่ได้");
        //     return
        //   } else {
        //    console.log(" ยื่มได้");
        //     text_a = "ยื่มได้";

        //   }

        // }
      });
      console.log(xx);
      console.log(_arr);
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (_arr.length == 0) {
        //บันทึก
        console.log("บันทึกข้อมูล");

        const data_a = {
          equipment_code: this.registration_number || this.equipment_code,
          req_date: " ",
          budget_year: this.budget_year || " ",
          important_number: this.important_number || this.post_data.important_number,
          evidence_type: this.proof ? this.proof.evidence || this.proof : " ",
          action_date: " ",
          work_gid: this.workG.work_gid || this.post_data.work_gid,
          dep_id: this.department.dep_id || this.post_data.dep_id,
          req_name: this.req_name.fullname || this.post_data.req_name,
          reason: this.reason || this.post_data.reason,
          borrow_date: this.from.borrow_date,
          takeback_date: this.from.takeback_date,
          takeback_date_happen: this.from.takeback_date_happen,
          equipment_code_gen: this.equipment_code_gen,
          remark: ' '
        };
        if (this.equipment_code_gen == "false") {
          const url_del = `${API}borrow/${this.equipment_code}`;
          const res_del = await axios.delete(url_del, head);
          console.log(res_del.data);
        }
        const url = `${API}borrow`;
        const res_a = await axios.post(url, data_a, head);
        console.log(res_a.data.message);
        const url_b = `${API}borrowList`;
        this.getlog = res_a.data.message.equipment_code;
        if (res_a.data.status === 200) {
          const res_b = await Promise.all(
            this?.pickings?.map(
              async (res) =>
                await axios.post(
                  url_b,
                  {
                    work_gid: this.workG.work_gid || this.post_data.work_gid,
                    equipment_code: res_a.data.message.equipment_code || this.equipment_code,
                    e_id: res.e_id,
                    e_number: res.e_number,
                    serial_number: res.serial_number,
                    remark: res.remark || " ",
                  },
                  head
                )
            )
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึก",
            showConfirmButton: false,
            timer: 1500,
          });
          this.pickings = [];
          this.gettable();
          this.logUser("ยืมทรัพย์สิน");
          //บันทึก
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "บันทึกไม่สำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        console.log("ไม่บันทึกข้อมูล");
      }

      return;
    },
    async data_edit(data_edit) {
      // console.log(data_edit);
      const { access_token } = await this.access_token();
      const url = `${API}borrow/${data_edit.equipment_code}`;
      const data = {
        req_date: data_edit.req_date,
        budget_year: data_edit.budget_year,
        req_name: data_edit.req_name,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.put(url, data, head);
      console.log(res);
      this.gettable();
    },
    addItem() {
      // console.log(this.addItemList);
      this.pickings_temp.push(...this.addItemList);
      this.pickings.push(...this.addItemList);
      // console.log(this.pickings);
    },
    async logUser(text) {
      console.log(this.user);
      const { access_token } = await this.access_token();
      const url = `${API}addHistoryLogs`;
      const data = {
        username: this.user,
        event_name: `${text} ${this.getlog} `,
      };
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
      // console.log(res.data.message);
    },
    dateField: async function (props) {
      this.$router.push({
        name: "inborrowkuruplun",
        params: {
          rowData: props.row, // or anything you want
        },
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายการยืมทรัพย์สิน",
        columns: [
          {
            title: "รายการยืมทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วัน-เดือน-ปี (วันที่ยืม)",
                field: "takeback_date_th",
              },
              {
                title: "วัน-เดือน-ปี (วันที่คืน)",
                field: "takeback_date_happen_th",
              },
              {
                title: "เลขทะเบียน",
                field: "equipment_code",
              },
              {
                title: "กลุ่มงาน",
                field: "work_gname",
              },
              {
                title: "ผู้ยืม",
                field: "req_name",
              },

              {
                title: "สถานะ",
                field: "status_type",
              },
            ],
          },
        ],
      });
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.gettable();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.gettable();
    },

    async gettable() {
      this.show = true;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const url2 = `${API}borrowHistory?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const res2 = await axios.get(url2, head);
      const data2 = await res2.data.message.data;
      console.log(res2.data.message);

      // console.log(data2)
      //   console.log(res2)
      if (this.status_user == "ผู้ใช้ทั่วไป") {
        const url1 = `${API}filterTaskByUser?username=${this.username_user}&event_name=ยืมทรัพย์สิน`;
        const res1 = await axios.get(url1, head);
        const data1 = await res1.data.message.data.map((el) => {
          return {
            ...el,
            equipment_code: el.task_code,
          };
        });

        const ff = data2.filter((el) => {
          return data1.find((element) => {
            return element.equipment_code === el.equipment_code;
          });
        });

        this.rows = ff.map((element) => {
          return {
            ...element,
            borrow_date_th: `${parseInt(element.borrow_date.split("-")[2])} ${
              this.month[parseInt(element.borrow_date.split("-")[1])]
            } ${Number(element.borrow_date.split("-")[0]) + 543}`,
            takeback_date_th: `${parseInt(element.takeback_date.split("-")[2])} ${
              this.month[parseInt(element.takeback_date.split("-")[1])]
            } ${Number(element.takeback_date.split("-")[0]) + 543}`,
            takeback_date_happen_th: `${parseInt(element.takeback_date_happen.split("-")[2])} ${
              this.month[parseInt(element.takeback_date_happen.split("-")[1])]
            } ${Number(element.takeback_date_happen.split("-")[0]) + 543}`,
            updatedAt_th: `${parseInt(element.updatedAt.split("-")[2])} ${
              this.month[parseInt(element.updatedAt.split("-")[1])]
            } ${Number(element.updatedAt.split("-")[0]) + 543}`,
          };
        });
        this.total = this.rows.length;
        this.show = false;
      } else {
        this.total = res2.data.message.total;
        this.rows = res2.data.message.data.map((element) => {
          console.log(element);
          return {
            ...element,
            status_type: element.status_type === "ยืมครุภัณฑ์" ? "ยืม" : element.status_type,
            borrow_date_th: `${parseInt(element.borrow_date.split("-")[2])} ${
              this.month[parseInt(element.borrow_date.split("-")[1])]
            } ${Number(element.borrow_date.split("-")[0]) + 543}`,
            takeback_date_th: `${parseInt(element.takeback_date.split("-")[2])} ${
              this.month[parseInt(element.takeback_date.split("-")[1])]
            } ${Number(element.takeback_date.split("-")[0]) + 543}`,
            takeback_date_happen_th: `${parseInt(element.takeback_date_happen.split("-")[2])} ${
              this.month[parseInt(element.takeback_date_happen.split("-")[1])]
            } ${Number(element.takeback_date_happen.split("-")[0]) + 543}`,
            updatedAt_th: `${parseInt(element.updatedAt.split("-")[2])} ${
              this.month[parseInt(element.updatedAt.split("-")[1])]
            } ${Number(element.updatedAt.split("-")[0]) + 543}`,
          };
        });

        this.show = false;
      }
    },
    async selectionChanged(params) {
      this.addItemList = params.selectedRows;
      // this.pickings = params.selectedRows;
    },
    async item_select(params) {
      const { access_token } = await this.access_token();
      // console.log(params.e_gid);
      this.e_gid = params.e_gid;
      const url = `${API}withdrawList?e_gid=${params.e_gid}&e_status=true`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_data = await axios.get(url, head);
      console.log(res_data.data.message.data);

      const tt = Math.ceil(res_data.data.message.total / 100);
      this.material = [];
      for (let index = 1; index <= tt; index++) {
        console.log(index);
        const url = `${API}withdrawList?e_gid=${this.e_gid}&e_status=true&_limit=100&_page=${index}`;
        const res_data = await axios.get(url, head);
        console.log(res_data);
        this.material.push(
          ...res_data.data.message.data.map((el) => {
            return {
              ...el,
              remark: "",
            };
          })
        );
      }
      this.show = false;
      return;

      const aa = res_data.data.message.data.map((el) => {
        return {
          ...el,
          remark: "",
        };
      });

      this.material = aa;
      // console.log(this.material);
    },
    async item() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}equipmentGroups?_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      // console.log(res_workgroups.data.message.data);
      const res_amp = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
      this.selectedGs = res_amp;
    },
    async work_select(event) {
      //console.log(event);
      const { access_token } = await this.access_token();
      const m_gid = event.work_gid;
      const work_gname = event.work_gname;
      // console.log(m_gid);
      const url = `${API}departments?work_gid=${m_gid}&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          dep_id_dep_name: `${element.dep_id} - ${element.dep_name}`,
        };
      });
      this.departments = res_amp;

      const url_herd = `${API}headWorkgroup?work_gid=${work_gname}`;
      console.log(url_herd);
      const header_Workgroup = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_heard = await axios.get(url_herd, header_Workgroup);
      const [user] = res_heard.data.message.data.map((el) => {
        return {
          ...el,
          work_gid: `${el.workgroup.split("-")[0].replace(" ", "")}`,
          work_gid_work_gname: `${el.workgroup}`,
          dep_id: `${el.depname.split("-")[0].replace(" ", "")}`,
          dep_id_dep_name: `${el.depname}`,
          fullname: `${el.title}${el.firstname} ${el.lastname}`,
        };
      });
      this.department = user;
      this.req_name = user;
    },
    async work() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      // console.log(res_workgroups.data.message.data);
      const res_amp = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.workGs = res_amp;
    },
    async material_code() {
      const { access_token } = await this.access_token();
      const url = `${API}borrow`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const data_res = await axios.get(url, header);
      console.log(data_res);
      const { equipment_code, code_number, budget_year } = data_res.data.message.data[0];
      const materialcode = `${equipment_code}-${code_number.toString().padStart(3, "0")}-${budget_year}`;
      this.equipment_code = data_res.data.message.equipmentCodes.equipment_code_mix.replace("-NaN-", "001-");
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
